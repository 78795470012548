import { combineReducers, createReducer } from "../utils"
import {
  QUOTE_GET_SUCCESS,
  ADDONS_ADD_SUCCESS,
  ADDONS_REMOVE_SUCCESS,
  ADDONS_ADD_PROCESS,
  DISCOUNT_ADD_SUCCESS,
  SET_SLOT_PREFERENCE_SUCCESS,
  GET_RESERVED_QUOTE_SUCCESS,
  TAX_TOTAL,
  GET_TAXJAR_CALCULATION,
  CLEAR_TAXJAR_CALCULATION,
  GET_TAXJAR_CALCULATION_MOBILE,
  QUOTE_RECORD_CLEAR,
  TERMS_CONDITIONS,
  GET_SHOP_DISCOUNTS_SUCCESS,
  UPDATE_ADDONS_AND_SLOT_PREFERENCE,
  HAS_SET_SLOT_PREFERENCE_BEEN_CALLED
} from "../action-types";

const data = createReducer({}, {
  [QUOTE_GET_SUCCESS]: (state, { items }) => items,
  [QUOTE_RECORD_CLEAR]: (state, { data }) => data || {},
});

const addAddonProcess = createReducer(false, {
  [ADDONS_ADD_PROCESS]: (state, { addAddonProcess }) => addAddonProcess
});

const addOnsData = createReducer([], {
  [ADDONS_ADD_SUCCESS]: (state, { addOnsData }) => addOnsData,
  [UPDATE_ADDONS_AND_SLOT_PREFERENCE]: (state, { addOnsData }) => addOnsData,
  [ADDONS_REMOVE_SUCCESS]: (state, { addOnsData }) => addOnsData,
  [QUOTE_RECORD_CLEAR]: (state, { addOnsData }) => addOnsData || [],
});

const addon_total = createReducer(0, {
  [ADDONS_ADD_SUCCESS]: (state, { addon_total }) => addon_total,
  [UPDATE_ADDONS_AND_SLOT_PREFERENCE] : (state, { addon_total }) => addon_total,
  [ADDONS_REMOVE_SUCCESS]: (state, { addon_total }) => addon_total,
  [QUOTE_RECORD_CLEAR]: (state, { addon_total }) => addon_total || 0,
});

const discountList = createReducer([], {
  [DISCOUNT_ADD_SUCCESS]: (state, { discountList }) => discountList,
  [QUOTE_RECORD_CLEAR]: (state, { discountList }) => discountList || [],
})

const discountTotal = createReducer([], {
  [DISCOUNT_ADD_SUCCESS]: (state, { discountTotal }) => discountTotal,
  [QUOTE_RECORD_CLEAR]: (state, { discountTotal }) => discountTotal || [],
})

const serviceLocation = createReducer("", {
  [SET_SLOT_PREFERENCE_SUCCESS]: (state, { serviceLocation }) => serviceLocation || "",
  [UPDATE_ADDONS_AND_SLOT_PREFERENCE]: (state, { serviceLocation }) => serviceLocation || "",
  [QUOTE_RECORD_CLEAR]: state => (""),
});

const waitPreference = createReducer("", {
  [SET_SLOT_PREFERENCE_SUCCESS]: (state, { waitPreference }) => waitPreference,
  [UPDATE_ADDONS_AND_SLOT_PREFERENCE]: (state, { waitPreference }) => waitPreference,
  [QUOTE_RECORD_CLEAR]: state => (""),
});

const selectedSlot = createReducer({}, {
  [SET_SLOT_PREFERENCE_SUCCESS]: (state, { selectedSlot }) => selectedSlot,
  [UPDATE_ADDONS_AND_SLOT_PREFERENCE]: (state, { selectedSlot }) => selectedSlot,
  [QUOTE_RECORD_CLEAR]: state => ({}),
});

const selectedIndex = createReducer({}, {
  [SET_SLOT_PREFERENCE_SUCCESS]: (state, { selectedIndex }) => selectedIndex || -1,
  [UPDATE_ADDONS_AND_SLOT_PREFERENCE]: (state, { selectedIndex }) => selectedIndex || -1,
  [QUOTE_RECORD_CLEAR]: state => ({}),
});

const reservedQuotes = createReducer([], {
  [GET_RESERVED_QUOTE_SUCCESS]: (state, { data }) => data,
});

export const taxTotal = createReducer(0, {
  [TAX_TOTAL]: (state, { taxTotal }) => taxTotal,
  [QUOTE_RECORD_CLEAR]: (state, { taxTotal }) => taxTotal || 0,
});

export const updateTermsConditions = createReducer(false, {
  [TERMS_CONDITIONS]: (state, { termsConditions }) => termsConditions,
  [QUOTE_RECORD_CLEAR]: state => (false),
});

const taxjarCalculation = createReducer({}, {
  [GET_TAXJAR_CALCULATION]: (state, { taxjarCalculation }) => taxjarCalculation,
  [CLEAR_TAXJAR_CALCULATION]: state => ({}),
  [QUOTE_RECORD_CLEAR]: state => ({}),
});

const taxjarCalculationMobile = createReducer({}, {
  [GET_TAXJAR_CALCULATION_MOBILE]: (state, { taxjarCalculationMobile }) => taxjarCalculationMobile,
  [CLEAR_TAXJAR_CALCULATION]: state => ({}),
  [QUOTE_RECORD_CLEAR]: state => ({}),
});

const shopDiscounts = createReducer([], {
  [GET_SHOP_DISCOUNTS_SUCCESS]: (state, { data }) => data,
  [QUOTE_RECORD_CLEAR]: state => ([]),
})

const hasSetSlotPreferenceBeenCalled = createReducer(true, {
  [HAS_SET_SLOT_PREFERENCE_BEEN_CALLED]: (state, { hasSetSlotPreferenceBeenCalled }) => hasSetSlotPreferenceBeenCalled
});

const slotPrefence = combineReducers({
  serviceLocation,
  waitPreference,
  selectedSlot,
  selectedIndex
});

export default combineReducers({
  data,
  reservedQuotes,
  addAddonProcess,
  addOnsData,
  addon_total,
  discountList,
  discountTotal,
  slotPrefence,
  taxTotal,
  taxjarCalculation,
  taxjarCalculationMobile,
  updateTermsConditions,
  shopDiscounts,
  hasSetSlotPreferenceBeenCalled
})   