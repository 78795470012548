// /** @format */
// /**
//  * Any new action type should be added to the set of exports below, with the
//  * value mirroring its exported name.
//  *
//  * Please keep this list alphabetized!
//  *
//  * Unsure how to name an action type? Refer to our guidelines:
//  *  - https://wpcalypso.wordpress.com/devdocs/docs/our-approach-to-data.md#actions
//  */

// widget/actions.js
export const CLEAR_WIDGET_ELEMENTS = 'CLEAR_WIDGET_ELEMENTS';
export const JTWIDGET_CLEAR_RECORD = 'JTWIDGET_CLEAR_RECORD';
export const HANDLE_NAVIGATION_INDEX = 'HANDLE_NAVIGATION_INDEX';
export const JTWIDGET_GET_SUCCESS = 'JTWIDGET_GET_SUCCESS';
export const JTWIDGET_GET_ERRORED = 'JTWIDGET_GET_ERRORED';
export const WINDOW_STATE_CHANGE = 'WINDOW_STATE_CHANGE';
export const WIDGET_ENABLE_UI = 'WIDGET_ENABLE_UI';
export const SET_SHOW_WINDOW_SIZE_BUTTON = 'SET_SHOW_WINDOW_SIZE_BUTTON';
export const SET_NATIVE_MODE = 'SET_NATIVE_MODE';
export const SET_CALL_HREF = 'SET_CALL_HREF';
export const STORE_DEVICE_PLATFORM = 'STORE_DEVICE_PLATFORM';
export const SET_CUSTOMER_GEO_LOCATION = 'SET_CUSTOMER_GEO_LOCATION';
// TODO: check weather WIDGET_PUSH_MESSAGE import is require 
// export const WIDGET_PUSH_MESSAGE = 'WIDGET_PUSH_MESSAGE';
export const WIDGET_OPERATION = 'WIDGET_OPERATION';
export const WIDGET_ID_CHANGE = 'WIDGET_ID_CHANGE';
export const WIDGET_CRUD_REQUESTED = 'WIDGET_CRUD_REQUESTED';
export const WIDGET_CRUD_ERRORED = 'WIDGET_CRUD_ERRORED';
export const SERVICE_NAME_CHANGE = 'SERVICE_NAME_CHANGE';
export const SERVICE_ID_CHANGE = 'SERVICE_ID_CHANGE';
export const FORM_DATA_CHANGED = 'FORM_DATA_CHANGED';
export const FORM_SET_STEP_INDEX = 'FORM_SET_STEP_INDEX';
export const FORM_SET_SAVE_ERROR = 'FORM_SET_SAVE_ERROR';
export const FORM_SET_BID_REQUEST_REFERENCE = 'FORM_SET_BID_REQUEST_REFERENCE';
export const FORM_ERRORS_CHANGED = 'FORM_ERRORS_CHANGED';
export const SEARCH_SERVICE_SUCCESS = 'SEARCH_SERVICE_SUCCESS';
export const PROMOTE_SERVICE_SUCCESS = 'PROMOTE_SERVICE_SUCCESS';
export const SET_MULTIPLE_SERVICES = 'SET_MULTIPLE_SERVICES'
export const JOURNEY_TEMPLATE_GET_SUCCESS = 'JOURNEY_TEMPLATE_GET_SUCCESS';
export const GET_JOURNEY_TEMPLATE_UI_SCHEMA_ELEMENTS_SUCCESS = 'GET_JOURNEY_TEMPLATE_UI_SCHEMA_ELEMENTS_SUCCESS'
export const BID_CREATED_SUCCESS = 'BID_CREATED_SUCCESS';
export const BID_UPDATE_S3_UPLOAD_SUCCESS = 'BID_UPDATE_S3_UPLOAD_SUCCESS';
export const BID_UPDATE_SUCCESS = 'BID_UPDATE_SUCCESS';
export const BID_UPLOAD_PROGRESS = 'BID_UPLOAD_PROGRESS';
export const BID_PENDING_PROMISES = 'BID_PENDING_PROMISES';
export const BID_UPLOAD_PROGRESSES_DATA = 'BID_UPLOAD_PROGRESSES_DATA';
export const BID_GET_CONTACT = 'BID_GET_CONTACT'
export const BID_GET_ADDITIONAL_CONTACT = 'BID_GET_ADDITIONAL_CONTACT'
export const DEV_MODE_CHANGE = 'DEV_MODE_CHANGE';
export const CLEAR_JT_ELEMENTS = "CLEAR_JT_ELEMENTS";
export const AWSS3_UPLOAD_ERROR = "AWSS3_UPLOAD_ERROR";
export const STORE_AUTH_TOKEN = "STORE_AUTH_TOKEN";

// communication/actions.js
export const COMMUNICATIONS_GET_SUCCESS = 'COMMUNICATIONS_GET_SUCCESS';
export const COMMUNICATIONS_GET_ERRORED = 'COMMUNICATIONS_GET_ERRORED';
export const COMMUNICATIONS_PATCH_ERRORED = 'COMMUNICATIONS_PATCH_ERRORED';
export const COMMUNICATIONS_MSG_GET_SUCCESS = 'COMMUNICATIONS_MSG_GET_SUCCESS';

// state/util.js
export const SERIALIZE = 'SERIALIZE';
export const DESERIALIZE = 'DESERIALIZE';

// chat/actions.js
export const CHAT_TOKEN_REQUESTED = 'CHAT_TOKEN_REQUESTED';
export const CHAT_TOKEN_ERRORED = 'CHAT_TOKEN_ERRORED';
export const CHAT_TOKEN_SUCCESS = 'CHAT_TOKEN_SUCCESS';

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const FIND_ONLINE_PROVIDER_SUCCESS = 'FIND_ONLINE_PROVIDER_SUCCESS';
export const ADD_CHAT_MESSAGE_SUCCESS = 'ADD_CHAT_MESSAGE_SUCCESS';
export const COMPLETE_CHAT_SUCCESS = 'COMPLETE_CHAT_SUCCESS';
export const UPDATE_CUSTOMER_CHAT_SUCCESS = 'UPDATE_CUSTOMER_CHAT_SUCCESS';
export const CHAT_CRUD_REQUESTED = 'CHAT_CRUD_REQUESTED';
export const CHAT_CRUD_ERRORED = 'CHAT_CRUD_ERRORED';
export const CHAT_STATUS_MESSAGE = 'CHAT_STATUS_MESSAGE';
export const PROVIDER_USER_HEARTBIT_LOST = 'PROVIDER_USER_HEARTBIT_LOST';
export const PROVIDER_USER_HEARTBIT_SUCCESS = 'PROVIDER_USER_HEARTBIT_SUCCESS';

// quote/actions.js
export const QUOTE_GET_SUCCESS = 'QUOTE_GET_SUCCESS';
export const PROVIDER_GET_SUCCESS = 'PROVIDER_GET_SUCCESS';
export const PROVIDER_USER_SUCCESS = "PROVIDER_USER_SUCCESS";
export const QUOTE_GET_ERRORED = 'QUOTE_GET_ERRORED';
export const SET_SLOT_PREFERENCE_SUCCESS = 'SET_SLOT_PREFERENCE_SUCCESS';
export const JOB_GET_SUCCESS = 'JOB_GET_SUCCESS';
export const GET_ADDITIONAL_QUOTE_SUCCESS = 'QUOTE_GET_BY_ID_SUCCESS';
export const GET_RESERVED_QUOTE_SUCCESS = 'GET_RESERVED_QUOTE_SUCCESS';
export const TAX_TOTAL = "TAX_TOTAL";
export const GET_TAXJAR_CALCULATION = "GET_TAXJAR_CALCULATION";
export const CLEAR_TAXJAR_CALCULATION = "CLEAR_TAXJAR_CALCULATION"
export const TERMS_CONDITIONS = "TERMS_CONDITIONS"
export const TERMS_CONDITIONS_OLD = "TERMS_CONDITIONS_OLD"
export const HAS_SET_SLOT_PREFERENCE_BEEN_CALLED = "HAS_SET_SLOT_PREFERENCE_BEEN_CALLED";
// blot/actions.js
export const BLOT_ROUTE_WITH_DATA = 'BLOT_ROUTE_WITH_DATA';

// menu/actions.js
export const MENU_GET_SUCCESS = 'MENU_GET_SUCCESS';
export const MENU_ADD_REMOVE_SUCCESS = 'MENU_ADD_REMOVE_SUCCESS';

//Bid/actions.js

export const BID_GET_ERRORED = 'BID_GET_ERRORED';
export const BID_GET_SUCCESS = 'BID_GET_SUCCESS';
export const BID_RECORD_DELET = 'BID_RECORD_DELET';
export const BID_PATCH_STATUS = "BID_PATCH_STATUS";

// shop/actions.js
export const SLOTMATRIX_GET_SUCCESS = 'SLOTMATRIX_GET_SUCCESS';
export const SLOTMATRIX_GET_ERRORED = 'SLOTMATRIX_GET_ERRORED';
export const SHOP_DATA_GET_SUCCESS = 'SHOP_DATA_GET_SUCCESS';


//JourneyTemplate/actions.js

export const JOURNEYTEMPLATE_GET_SUCCESS = 'JOURNEYTEMPLATE_GET_SUCCESS';
export const JOURNEYTEMPLATE_GET_ERRORED = 'JOURNEYTEMPLATE_GET_ERRORED';
export const AWSS3_UPLOAD_CHAT_API_SUCCESS = 'AWSS3_UPLOAD_CHAT_API_SUCCESS';
export const AWSS3_UPLOAD_CHAT_API_ERRORED = 'AWSS3_UPLOAD_CHAT_API_ERRORED';

export const SET_CHANNEL_ID = 'SET_CHANNEL_ID';
export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';
export const SET_CHAT_CLIENT = 'SET_CHAT_CLIENT';
// addons/actions.js
export const ADDONS_ADD_SUCCESS = "ADDONS_ADD_SUCCESS";
export const ADDONS_REMOVE_SUCCESS = "ADDONS_REMOVE_SUCCESS";
export const ADDONS_CRUD_ERRORED = "ADDONS_CRUD_ERRORED";
export const ADDONS_GET_DETAILS_SUCCESS = "ADDONS_GET_DETAILS_SUCCESS";
export const ADDON_ADD_SUCCESS = "ADDON_ADD_SUCCESS";
export const ADDON_REMOVE_SUCCESS = "ADDON_REMOVE_SUCCESS";
export const ADDONS_ADD_PROCESS = "ADDONS_ADD_PROCESS";
export const ADDDON_REQUESTED = "ADDDON_REQUESTED";
export const ADDON_UPDATE_DISCOUNT_TOTAL_SUCCESS = "ADDON_UPDATE_DISCOUNT_TOTAL_SUCCESS";
export const SET_MESSAGE_INPUT_SUCCESS = "SET_MESSAGE_INPUT_SUCCESS";

export const CHANGE_EMAIL_OPT_IN_PREFERANCE_SUCCESS = "CHANGE_EMAIL_OPT_IN_PREFERANCE_SUCCESS";
export const EMAIL_OPT_IN_PREFERANCE_CRUD_ERRORED = "EMAIL_OPT_IN_PREFERANCE_CRUD_ERRORED";
export const CHANGE_EMAIL_OPT_IN_PREFERANCE_REQUESTED = "CHANGE_EMAIL_OPT_IN_PREFERANCE_REQUESTED";

//discount/actions.js
export const DISCOUNT_LIST = "DISCOUNT_LIST";
export const DISCOUNT_TOTAL = 'DISCOUNT_TOTAL';
export const DISCOUNT_ADD_SUCCESS = 'DISCOUNT_ADD_SUCCESS';

export const QUOTE_RECORD_CLEAR = "QUOTE_RECORD_CLEAR";
export const SET_WIDGET_ON_OPEN_OPERATIONS = "SET_WIDGET_ON_OPEN_OPERATIONS";
export const SET_WIDGET_CUSTOM_MESSAGE = "SET_WIDGET_CUSTOM_MESSAGE";
export const SET_WIDGET_PROMOTED_SERVICE_VIEW = "SET_WIDGET_PROMOTED_SERVICE_VIEW";
export const SET_WIDGET_CUSTOM_MESSAGE_VISIBLE = "SET_WIDGET_CUSTOM_MESSAGE_VISIBLE";
export const SET_WIDGET_PROMOTED_SERVICE_TITLE_VISIBLE = "SET_WIDGET_PROMOTED_SERVICE_TITLE_VISIBLE";
export const SET_WIDGET_PROMOTED_SHOP_TITLE_VISIBLE = "SET_WIDGET_PROMOTED_SHOP_TITLE_VISIBLE";
export const SET_WIDGET_PROMOTED_SHOP_AREA_VISIBLE = "SET_WIDGET_PROMOTED_SHOP_AREA_VISIBLE";
export const SET_WIDGET_CAROSEL_SPEED_IN_MILLIS = "SET_WIDGET_CAROSEL_SPEED_IN_MILLIS";
export const SET_WIDGET_MAIN_SEARCH_TITLE_VISIBLE = "SET_WIDGET_MAIN_SEARCH_TITLE_VISIBLE";
export const SET_HEADER_VISIBLE = "SET_HEADER_VISIBLE";
export const SET_LOCALSTORAGE_READ_4_PROPERTY = "SET_LOCALSTORAGE_READ_4_PROPERTY";
export const SET_FOOTER_VISIBLE = "SET_FOOTER_VISIBLE";
export const SET_MENU_VISIBLE = "SET_MENU_VISIBLE";
export const SHOP_SERVICE_DATA = 'SHOP_SERVICE_DATA'
export const SLOT_AVIALIBILITY_FOR_MONTH = 'SLOT_AVIALIBILITY_FOR_MONTH'
export const SERVICE_DATA = 'SERVICE_DATA'
export const ADDONS_DISCOUNT_ADDED = "ADDONS_DISCOUNT_ADDED"
export const GET_TAXJAR_CALCULATION_MOBILE = "GET_TAXJAR_CALCULATION_MOBILE"

export const SET_SHOW_HOW_TO = "SET_SHOW_HOW_TO";
export const SET_SHOW_SERVICE_AREA_SEARCH = "SET_SHOW_SERVICE_AREA_SEARCH";

export const SET_MAIN_SEARCH_AREA = "SET_MAIN_SEARCH_AREA";
export const SET_PROMOTED_SERVICE_AREA = "SET_PROMOTED_SERVICE_AREA";

export const SET_CONTACT_LINK = "SET_CONTACT_LINK";
export const SET_CONTACT_ALLOW_2_EDIT = "SET_CONTACT_ALLOW_2_EDIT";
export const BID_PATCH_PROMISES = "BID_PATCH_PROMISES"

export const SET_SERVICE_REQUEST_IMAGE_URL_AND_KEY = "SET_SERVICE_REQUEST_IMAGE_URL_AND_KEY";
export const CLEAR_SERVICE_REQUEST_IMAGE_URL_AND_KEY = "CLEAR_SERVICE_REQUEST_IMAGE_URL_AND_KEY";
export const GET_SHOP_DISCOUNTS_SUCCESS = "GET_SHOP_DISCOUNTS_SUCCESS";
export const UPDATE_ADDONS_DISCOUNTS_AND_SLOT_PREFERENCE = "UPDATE_ADDONS_DISCOUNTS_AND_SLOT_PREFERENCE";
export const ADDONS_DISCOUNTS_CHANGED_QUOTEV2 = "ADDONS_DISCOUNTS_CHANGED_QUOTEV2";
