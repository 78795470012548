
export const BID_REF_LEAD_SOURCE = {
 LEAD_SOURCE_VALUE_WEB: "Bidclips Widget", 
 LEAD_SOURCE_VALUE_ANDROID:"Bidclips Widget Android App",
 LEAD_SOURCE_VALUE_IOS:"Bidclips Widget ios App",   
};


//TODO: After Every Release Change the Widget Version Here.
export const WIDGET_VERSION = {
    WIDGET_RELEASE_VERSION: "1.26.1"
}